// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Button from "../../../../../../../../../styleguide/components/Button/Button.res.js";
import * as GoogleMap from "../../../../../../../../../bindings/GoogleMap.res.js";
import * as IconClose from "../../../../../../../../../styleguide/icons/IconClose.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationShowContentMapInfoBoxTile from "../info-box-tile/LocationShowContentMapInfoBoxTile.res.js";
import * as LocationShowContentMapInfoBoxScss from "./LocationShowContentMapInfoBox.scss";

var css = LocationShowContentMapInfoBoxScss;

function LocationShowContentMapInfoBox(props) {
  var $$location = props.location;
  return JsxRuntime.jsx(GoogleMap.InfoBox.make, {
              position: GoogleMap.Coordinates.fromLocationShowForMap($$location),
              contentWidth: 270.0,
              contentHeight: 332.0,
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(Button.make, {
                            size: "MD",
                            color: "Primary",
                            className: css.closeBtn,
                            onClick: props.closeInfoBox,
                            children: JsxRuntime.jsx(IconClose.make, {
                                  title: "Close",
                                  size: "MD",
                                  color: "White"
                                })
                          }),
                      JsxRuntime.jsx(LocationShowContentMapInfoBoxTile.make, {
                            provider: props.provider,
                            location: $$location
                          }),
                      JsxRuntime.jsx("div", {
                            className: css.arrow
                          })
                    ],
                    className: css.infoWindow
                  })
            });
}

var make = LocationShowContentMapInfoBox;

export {
  css ,
  make ,
}
/* css Not a pure module */
