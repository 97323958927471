// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Digest from "rescript/lib/es6/digest.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as GoogleMap from "../../../bindings/GoogleMap.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";

function coordinatesHash(coordinates) {
  return Digest.string(Js_array.joinWith("|", coordinates !== undefined ? Belt_Array.map(coordinates, GoogleMap.Coordinates.toString) : []));
}

function onLoad(map, bounds) {
  GoogleMap.Helpers.fitBounds(map, bounds);
}

var Helpers = {
  coordinatesHash: coordinatesHash,
  onLoad: onLoad
};

function GoogleMapWithBounds(props) {
  var bounds = props.bounds;
  var mapRef = React.useRef(undefined);
  React.useEffect((function () {
          var map = mapRef.current;
          if (map !== undefined) {
            GoogleMap.Helpers.fitBounds(Caml_option.valFromOption(map), bounds);
          }
          
        }), [coordinatesHash(bounds)]);
  return JsxRuntime.jsx(GoogleMap.make, {
              zoom: props.zoom,
              center: props.center,
              style: props.style,
              options: props.options,
              mapContainerClassName: props.mapContainerClassName,
              onLoad: (function (map) {
                  onLoad(map, bounds);
                  mapRef.current = Caml_option.some(map);
                }),
              children: props.children
            });
}

var make = GoogleMapWithBounds;

export {
  Helpers ,
  make ,
}
/* react Not a pure module */
