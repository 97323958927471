// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";

function toJs(style) {
  return {
          hue: Js_null_undefined.fromOption(Caml_option.undefined_to_opt(style.hue)),
          lightness: Js_null_undefined.fromOption(Caml_option.undefined_to_opt(style.lightness)),
          saturation: Js_null_undefined.fromOption(Caml_option.undefined_to_opt(style.saturation)),
          gamma: Js_null_undefined.fromOption(Caml_option.undefined_to_opt(style.gamma)),
          visibility: Js_null_undefined.fromOption(Caml_option.undefined_to_opt(style.visibility)),
          color: Js_null_undefined.fromOption(Caml_option.undefined_to_opt(style.color)),
          weight: Js_null_undefined.fromOption(Caml_option.undefined_to_opt(style.weight))
        };
}

var Styler = {
  toJs: toJs
};

function toJs$1(record) {
  return {
          featureType: Js_null_undefined.fromOption(Caml_option.undefined_to_opt(record.featureType)),
          elementType: Js_null_undefined.fromOption(Caml_option.undefined_to_opt(record.elementType)),
          stylers: Belt_Array.map(Belt_List.toArray(record.stylers), toJs)
        };
}

var Style = {
  toJs: toJs$1
};

function toJs$2(styles) {
  return Belt_Array.map(Belt_List.toArray(styles), toJs$1);
}

var grey_0 = {
  elementType: "geometry",
  stylers: {
    hd: {
      color: "#f5f5f5"
    },
    tl: /* [] */0
  }
};

var grey_1 = {
  hd: {
    elementType: "labels.text.fill",
    stylers: {
      hd: {
        color: "#616161"
      },
      tl: /* [] */0
    }
  },
  tl: {
    hd: {
      elementType: "labels.text.stroke",
      stylers: {
        hd: {
          color: "#f5f5f5"
        },
        tl: /* [] */0
      }
    },
    tl: {
      hd: {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: {
          hd: {
            color: "#bdbdbd"
          },
          tl: /* [] */0
        }
      },
      tl: {
        hd: {
          featureType: "poi",
          stylers: {
            hd: {
              visibility: "off"
            },
            tl: /* [] */0
          }
        },
        tl: {
          hd: {
            featureType: "poi",
            elementType: "geometry",
            stylers: {
              hd: {
                color: "#eeeeee"
              },
              tl: /* [] */0
            }
          },
          tl: {
            hd: {
              featureType: "poi",
              elementType: "labels.text.fill",
              stylers: {
                hd: {
                  color: "#757575"
                },
                tl: /* [] */0
              }
            },
            tl: {
              hd: {
                featureType: "poi.park",
                elementType: "geometry",
                stylers: {
                  hd: {
                    color: "#e5e5e5"
                  },
                  tl: /* [] */0
                }
              },
              tl: {
                hd: {
                  featureType: "poi.park",
                  elementType: "labels.text.fill",
                  stylers: {
                    hd: {
                      color: "#9e9e9e"
                    },
                    tl: /* [] */0
                  }
                },
                tl: {
                  hd: {
                    featureType: "poi.business",
                    elementType: "geometry",
                    stylers: {
                      hd: {
                        visibility: "off"
                      },
                      tl: /* [] */0
                    }
                  },
                  tl: {
                    hd: {
                      featureType: "road",
                      elementType: "geometry",
                      stylers: {
                        hd: {
                          color: "#ffffff"
                        },
                        tl: /* [] */0
                      }
                    },
                    tl: {
                      hd: {
                        featureType: "road",
                        elementType: "labels.icon",
                        stylers: {
                          hd: {
                            saturation: -80.0
                          },
                          tl: {
                            hd: {
                              visibility: "on"
                            },
                            tl: /* [] */0
                          }
                        }
                      },
                      tl: {
                        hd: {
                          featureType: "road.arterial",
                          elementType: "labels.text.fill",
                          stylers: {
                            hd: {
                              color: "#757575"
                            },
                            tl: /* [] */0
                          }
                        },
                        tl: {
                          hd: {
                            featureType: "road.highway",
                            elementType: "geometry",
                            stylers: {
                              hd: {
                                color: "#dadada"
                              },
                              tl: /* [] */0
                            }
                          },
                          tl: {
                            hd: {
                              featureType: "road.highway",
                              elementType: "labels.text.fill",
                              stylers: {
                                hd: {
                                  color: "#616161"
                                },
                                tl: /* [] */0
                              }
                            },
                            tl: {
                              hd: {
                                featureType: "road.local",
                                elementType: "labels.text.fill",
                                stylers: {
                                  hd: {
                                    color: "#9e9e9e"
                                  },
                                  tl: /* [] */0
                                }
                              },
                              tl: {
                                hd: {
                                  featureType: "transit.line",
                                  elementType: "geometry",
                                  stylers: {
                                    hd: {
                                      color: "#e5e5e5"
                                    },
                                    tl: /* [] */0
                                  }
                                },
                                tl: {
                                  hd: {
                                    featureType: "transit.station",
                                    elementType: "geometry",
                                    stylers: {
                                      hd: {
                                        color: "#eeeeee"
                                      },
                                      tl: /* [] */0
                                    }
                                  },
                                  tl: {
                                    hd: {
                                      featureType: "water",
                                      elementType: "geometry",
                                      stylers: {
                                        hd: {
                                          color: "#e9edf0"
                                        },
                                        tl: /* [] */0
                                      }
                                    },
                                    tl: {
                                      hd: {
                                        featureType: "water",
                                        elementType: "labels.text.fill",
                                        stylers: {
                                          hd: {
                                            color: "#9e9e9e"
                                          },
                                          tl: /* [] */0
                                        }
                                      },
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

var grey = {
  hd: grey_0,
  tl: grey_1
};

var standard_0 = {
  featureType: "administrative.land_parcel",
  elementType: "labels",
  stylers: {
    hd: {
      visibility: "off"
    },
    tl: /* [] */0
  }
};

var standard_1 = {
  hd: {
    featureType: "poi",
    elementType: "labels.text",
    stylers: {
      hd: {
        visibility: "off"
      },
      tl: /* [] */0
    }
  },
  tl: {
    hd: {
      featureType: "poi.business",
      stylers: {
        hd: {
          visibility: "off"
        },
        tl: /* [] */0
      }
    },
    tl: {
      hd: {
        featureType: "road",
        elementType: "labels.icon",
        stylers: {
          hd: {
            visibility: "off"
          },
          tl: /* [] */0
        }
      },
      tl: {
        hd: {
          featureType: "road.local",
          elementType: "labels",
          stylers: {
            hd: {
              visibility: "off"
            },
            tl: /* [] */0
          }
        },
        tl: {
          hd: {
            featureType: "transit",
            stylers: {
              hd: {
                visibility: "off"
              },
              tl: /* [] */0
            }
          },
          tl: /* [] */0
        }
      }
    }
  }
};

var standard = {
  hd: standard_0,
  tl: standard_1
};

export {
  Styler ,
  Style ,
  toJs$2 as toJs,
  grey ,
  standard ,
}
/* No side effect */
