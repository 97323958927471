// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../../../../../../libs/ID.res.js";
import * as React from "react";
import * as GoogleMap from "../../../../../../../../bindings/GoogleMap.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as GoogleMapStyles from "../../../../../../../../styleguide/styles/GoogleMapStyles.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as GoogleMapWithBounds from "../../../../../../../../styleguide/components/GoogleMap/GoogleMapWithBounds.res.js";
import * as Global from "Client/common/global";
import * as Api from "@react-google-maps/api";
import * as LocationShowContentMapInfoBox from "./info-box/LocationShowContentMapInfoBox.res.js";
import * as LocationShowContentMapTabScss from "../LocationShowContentMapTab.scss";

var css = LocationShowContentMapTabScss;

function LocationShowContentMap(props) {
  var $$location = props.location;
  var match = React.useState(function () {
        return 0;
      });
  var setIndex = match[1];
  var match$1 = React.useReducer((function (_state, action) {
          if (typeof action !== "object") {
            return {
                    selectedMarkers: []
                  };
          } else {
            return {
                    selectedMarkers: action._0
                  };
          }
        }), {
        selectedMarkers: []
      });
  var dispatch = match$1[1];
  var state = match$1[0];
  var match$2 = state.selectedMarkers.length;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Api.LoadScript, {
                    googleMapsApiKey: Global.GoogleMapAPI,
                    libraries: Global.GoogleMapLibraries,
                    children: JsxRuntime.jsx("div", {
                          children: JsxRuntime.jsxs(GoogleMapWithBounds.make, {
                                mapContainerClassName: css.mapElement,
                                style: GoogleMapStyles.standard,
                                options: {
                                  mapTypeControl: false,
                                  zoomControl: true,
                                  zoomControlOptions: {
                                    position: 5
                                  },
                                  streetViewControl: false,
                                  fullscreenControl: false
                                },
                                bounds: [{
                                    lat: $$location.latitude,
                                    lng: $$location.longitude
                                  }],
                                children: [
                                  JsxRuntime.jsx(GoogleMap.MarkerClusterer.make, {
                                        averageCenter: true,
                                        enableRetinaIcons: true,
                                        minimumClusterSize: 2,
                                        styles: GoogleMap.MarkerClusterer.Style.$$default,
                                        zoomOnClick: false,
                                        onClick: (function (cluster) {
                                            var map = cluster.getMap();
                                            var size = cluster.getSize();
                                            if (size > 12) {
                                              return map.fitBounds(cluster.getBounds());
                                            }
                                            var markers = cluster.getMarkers();
                                            var locationIds = Belt_Array.map(markers, (function (prim) {
                                                    return prim.locationId;
                                                  }));
                                            var locations = Belt_Array.keep([$$location], (function ($$location) {
                                                    return Belt_Array.some(locationIds, (function (locationId) {
                                                                  return ID.eq(locationId, $$location.id);
                                                                }));
                                                  }));
                                            setIndex(function (param) {
                                                  return 0;
                                                });
                                            dispatch({
                                                  TAG: "OpenInfoBox",
                                                  _0: locations
                                                });
                                          }),
                                        onClusteringEnd: (function (clusterer) {
                                            var clusters = clusterer.getClusters();
                                            Belt_Array.forEach(clusters, (function (cluster) {
                                                    if (Belt_Array.some(cluster.getMarkers(), (function (marker) {
                                                              return marker.sponsored;
                                                            }))) {
                                                      cluster.clusterIcon.url = GoogleMap.MarkerClusterer.sponsoredImageUrl;
                                                      return ;
                                                    }
                                                    
                                                  }));
                                          }),
                                        children: (function (clusterer) {
                                            return Belt_Array.map([$$location], (function ($$location) {
                                                          return JsxRuntime.jsx(GoogleMap.Marker.make, {
                                                                      position: {
                                                                        lat: $$location.latitude,
                                                                        lng: $$location.longitude
                                                                      },
                                                                      onClick: (function () {
                                                                          setIndex(function (param) {
                                                                                return 0;
                                                                              });
                                                                          dispatch({
                                                                                TAG: "OpenInfoBox",
                                                                                _0: [$$location]
                                                                              });
                                                                        }),
                                                                      icon: $$location.sponsoredCity ? GoogleMap.Marker.Icon.sponsored : GoogleMap.Marker.Icon.$$default,
                                                                      clusterer: Caml_option.some(clusterer),
                                                                      noClustererRedraw: true,
                                                                      onLoad: (function (marker) {
                                                                          marker.locationId = $$location.id;
                                                                          marker.sponsored = $$location.sponsoredCity;
                                                                        })
                                                                    }, ID.toString($$location.id));
                                                        }));
                                          })
                                      }),
                                  match$2 !== 1 ? null : JsxRuntime.jsx(LocationShowContentMapInfoBox.make, {
                                          provider: props.provider,
                                          location: Belt_Array.getExn(state.selectedMarkers, 0),
                                          closeInfoBox: (function (param) {
                                              dispatch("CloseInfoBox");
                                            })
                                        })
                                ]
                              }),
                          className: css.mapContainer
                        })
                  }),
              className: css.map
            });
}

var make = LocationShowContentMap;

var $$default = LocationShowContentMap;

export {
  css ,
  make ,
  $$default as default,
}
/* css Not a pure module */
