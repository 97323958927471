// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Cloudinary from "../libs/Cloudinary.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as GoogleMapStyles from "../styleguide/styles/GoogleMapStyles.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Api from "@react-google-maps/api";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function fromLocation($$location) {
  return {
          lat: $$location.latitude,
          lng: $$location.longitude
        };
}

function fromLocationForMap($$location) {
  return {
          lat: $$location.latitude,
          lng: $$location.longitude
        };
}

function fromLocationShowForMap($$location) {
  return {
          lat: $$location.latitude,
          lng: $$location.longitude
        };
}

function fromLocationForProject($$location) {
  return {
          lat: $$location.latitude,
          lng: $$location.longitude
        };
}

function fromLocationForProjectsMap($$location) {
  return {
          lat: $$location.latitude,
          lng: $$location.longitude
        };
}

function toString(coordinates) {
  return String(coordinates.lat) + (";" + String(coordinates.lng));
}

var Coordinates = {
  fromLocation: fromLocation,
  fromLocationForMap: fromLocationForMap,
  fromLocationShowForMap: fromLocationShowForMap,
  fromLocationForProject: fromLocationForProject,
  fromLocationForProjectsMap: fromLocationForProjectsMap,
  toString: toString
};

var LatLng = {};

var LatLngBounds = {};

function toJs(options) {
  return {
          position: options.position
        };
}

var ZoomControlOptions = {
  toJs: toJs
};

function toJs$1(options) {
  return {
          mapTypeControl: options.mapTypeControl,
          zoomControl: options.zoomControl,
          zoomControlOptions: toJs(options.zoomControlOptions),
          streetViewControl: options.streetViewControl,
          fullscreenControl: options.fullscreenControl
        };
}

var Options = {
  toJs: toJs$1
};

var addStyledMap = (function(map, name, mapType){map.mapTypes.set(name, mapType)});

function setStyle(map, style) {
  var name = "DCMapStyled";
  var mapType = new (google.maps.StyledMapType)(style, {
        name: name
      });
  addStyledMap(map, name, mapType);
  return map.setMapTypeId(name);
}

var $$Map = {
  addStyledMap: addStyledMap,
  setStyle: setStyle
};

function setStyle$1(map, style) {
  if (Belt_Option.isSome(style)) {
    return setStyle(map, GoogleMapStyles.toJs(Belt_Option.getExn(style)));
  }
  
}

function fitBounds(map, coordinates) {
  if (coordinates === undefined) {
    return ;
  }
  if (coordinates.length === 0) {
    return map.setZoom(map.getZoom());
  }
  var bounds = new (google.maps.LatLngBounds)(undefined);
  Belt_Array.forEach(coordinates, (function (coordinate) {
          bounds.extend(new (google.maps.LatLng)(coordinate.lat, coordinate.lng));
        }));
  map.fitBounds(bounds);
}

function onLoad(map, zoom, style) {
  if (!Belt_Option.isSome(zoom)) {
    map.setZoom(2);
  }
  setStyle$1(map, style);
}

var Helpers = {
  setStyle: setStyle$1,
  fitBounds: fitBounds,
  onLoad: onLoad
};

var JsComponent = {};

function GoogleMap(props) {
  var onLoad$1 = props.onLoad;
  var mapContainerClassName = props.mapContainerClassName;
  var options = props.options;
  var style = props.style;
  var center = props.center;
  var zoom = props.zoom;
  var jsProps = {
    onLoad: (function (map) {
        onLoad(map, zoom, style);
        onLoad$1(map);
      }),
    children: props.children
  };
  if (Belt_Option.isSome(zoom)) {
    jsProps.zoom = zoom;
  }
  if (Belt_Option.isSome(center)) {
    jsProps.center = center;
  }
  if (Belt_Option.isSome(style)) {
    jsProps.style = style;
  }
  if (Belt_Option.isSome(options)) {
    jsProps.options = Belt_Option.map(options, toJs$1);
  }
  if (Belt_Option.isSome(mapContainerClassName)) {
    jsProps.mapContainerClassName = mapContainerClassName;
  }
  return JsxRuntime.jsx(Api.GoogleMap, jsProps);
}

var LoadScript = {};

var overlayPaneName = "floatPane";

var OverlayView = {
  overlayPaneName: overlayPaneName
};

var Clusterer = {};

function toJs$2(icon) {
  return {
          url: icon.url,
          size: new (google.maps.Size)(icon.size[0], icon.size[1]),
          origin: new (google.maps.Point)(icon.origin[0], icon.origin[1]),
          anchor: new (google.maps.Point)(icon.anchor[0], icon.anchor[1])
        };
}

var default_url = Cloudinary.imageUrl("f_auto,w_28,h_28/static/maps/marker-purple.png");

var default_size = [
  28,
  28
];

var default_origin = [
  0,
  0
];

var default_anchor = [
  14,
  14
];

var $$default = {
  url: default_url,
  size: default_size,
  origin: default_origin,
  anchor: default_anchor
};

var disabled_url = Cloudinary.imageUrl("f_auto,w_28,h_28/static/maps/marker-grey.png");

var disabled_size = [
  28,
  28
];

var disabled_origin = [
  0,
  0
];

var disabled_anchor = [
  14,
  14
];

var disabled = {
  url: disabled_url,
  size: disabled_size,
  origin: disabled_origin,
  anchor: disabled_anchor
};

var sponsored_url = Cloudinary.imageUrl("f_auto,w_28,h_28/static/maps/marker-orange.png");

var sponsored_size = [
  28,
  28
];

var sponsored_origin = [
  0,
  0
];

var sponsored_anchor = [
  14,
  14
];

var sponsored = {
  url: sponsored_url,
  size: sponsored_size,
  origin: sponsored_origin,
  anchor: sponsored_anchor
};

var red_url = Cloudinary.imageUrl("f_auto,w_28,h_28/static/maps/marker-red.png");

var red_size = [
  28,
  28
];

var red_origin = [
  0,
  0
];

var red_anchor = [
  14,
  14
];

var red = {
  url: red_url,
  size: red_size,
  origin: red_origin,
  anchor: red_anchor
};

var orange_url = Cloudinary.imageUrl("f_auto,w_28,h_28/static/maps/marker-orange-new.png");

var orange_size = [
  28,
  28
];

var orange_origin = [
  0,
  0
];

var orange_anchor = [
  14,
  14
];

var orange = {
  url: orange_url,
  size: orange_size,
  origin: orange_origin,
  anchor: orange_anchor
};

var Icon = {
  toJs: toJs$2,
  $$default: $$default,
  disabled: disabled,
  sponsored: sponsored,
  red: red,
  orange: orange
};

var JsComponent$1 = {};

function GoogleMap$Marker(props) {
  var noClustererRedraw = props.noClustererRedraw;
  var clusterer = props.clusterer;
  var icon = props.icon;
  var jsProps = {
    position: props.position,
    onClick: props.onClick,
    onLoad: props.onLoad,
    children: props.children
  };
  if (Belt_Option.isSome(icon)) {
    jsProps.icon = Belt_Option.map(icon, toJs$2);
  }
  if (Belt_Option.isSome(clusterer)) {
    jsProps.clusterer = clusterer;
  }
  if (Belt_Option.isSome(noClustererRedraw)) {
    jsProps.noClustererRedraw = noClustererRedraw;
  }
  return JsxRuntime.jsx(Api.Marker, jsProps);
}

var Marker = {
  Icon: Icon,
  JsComponent: JsComponent$1,
  make: GoogleMap$Marker
};

var Cluster = {};

var sponsoredImageUrl = Cloudinary.imageUrl("f_auto,w_46,h_46/static/maps/cluster-sponsored-purple.png");

var $$default$1 = [{
    textColor: "#ffffff",
    height: 46,
    width: 46,
    url: Cloudinary.imageUrl("f_auto,w_46,h_46/static/maps/cluster-purple.png")
  }];

var red$1 = [{
    textColor: "#ffffff",
    height: 46,
    width: 46,
    url: Cloudinary.imageUrl("f_auto,w_46,h_46/static/maps/cluster-red.png")
  }];

var orange$1 = [{
    textColor: "#ffffff",
    height: 46,
    width: 46,
    url: Cloudinary.imageUrl("f_auto,w_46,h_46/static/maps/cluster-orange.png")
  }];

var sponsored$1 = [{
    textColor: "#ffffff",
    height: 46,
    width: 46,
    url: sponsoredImageUrl
  }];

var Style = {
  $$default: $$default$1,
  red: red$1,
  orange: orange$1,
  sponsored: sponsored$1
};

var JsComponent$2 = {};

function GoogleMap$MarkerClusterer(props) {
  var __zoomOnClick = props.zoomOnClick;
  var zoomOnClick = __zoomOnClick !== undefined ? __zoomOnClick : true;
  return JsxRuntime.jsx(Api.MarkerClusterer, {
              averageCenter: props.averageCenter,
              enableRetinaIcons: props.enableRetinaIcons,
              gridSize: props.gridSize,
              minimumClusterSize: props.minimumClusterSize,
              imagePath: props.imagePath,
              styles: props.styles,
              zoomOnClick: zoomOnClick,
              onClick: props.onClick,
              onClusteringEnd: props.onClusteringEnd,
              children: props.children
            });
}

var MarkerClusterer = {
  sponsoredImageUrl: sponsoredImageUrl,
  Style: Style,
  JsComponent: JsComponent$2,
  make: GoogleMap$MarkerClusterer
};

function toJson(latLngBound) {
  return Json_Encode$JsonCombinators.object([
              [
                "north",
                latLngBound.north
              ],
              [
                "east",
                latLngBound.east
              ],
              [
                "south",
                latLngBound.south
              ],
              [
                "west",
                latLngBound.west
              ]
            ]);
}

var LatLngBoundsLiteral = {
  toJson: toJson
};

function toJson$1(circleBounds) {
  return Json_Encode$JsonCombinators.object([
              [
                "latitude",
                circleBounds.latitude
              ],
              [
                "longitude",
                circleBounds.longitude
              ],
              [
                "radius",
                circleBounds.radius
              ]
            ]);
}

var CircleBounds = {
  toJson: toJson$1
};

var ControlOptions = {};

function toJs$3(options) {
  return {
          draggable: options.draggable,
          editable: options.editable,
          fillColor: options.fillColor,
          fillOpacity: options.fillOpacity,
          strokeColor: options.strokeColor,
          strokeOpacity: options.strokeOpacity,
          strokeWeight: options.strokeWeight,
          geodesic: true
        };
}

var OverlayOptions = {
  toJs: toJs$3
};

function toJs$4(options) {
  return {
          drawingControl: options.drawingControl,
          drawingControlOptions: options.drawingControlOptions,
          rectangleOptions: toJs$3(options.overlayOptions),
          circleOptions: toJs$3(options.overlayOptions),
          polygonOptions: toJs$3(options.overlayOptions)
        };
}

var Options$1 = {
  toJs: toJs$4
};

var JsComponent$3 = {};

function GoogleMap$DrawingManager(props) {
  return JsxRuntime.jsx(Api.DrawingManager, {
              drawingMode: props.drawingMode,
              options: Belt_Option.map(props.options, toJs$4),
              onRectangleComplete: props.onRectangleComplete,
              onCircleComplete: props.onCircleComplete,
              onPolygonComplete: props.onPolygonComplete,
              children: props.children
            });
}

var DrawingManager = {
  LatLngBoundsLiteral: LatLngBoundsLiteral,
  CircleBounds: CircleBounds,
  ControlOptions: ControlOptions,
  OverlayOptions: OverlayOptions,
  Options: Options$1,
  JsComponent: JsComponent$3,
  make: GoogleMap$DrawingManager
};

function getPixelPositionOffset(width, height) {
  return {
          x: width / -2.0,
          y: height * -1.0 - 5.0
        };
}

function panBox(overlayView, position, contentWidth, contentHeight) {
  var map = overlayView.getMap();
  var mapBounds = map.getBounds();
  var offset = getPixelPositionOffset(contentWidth, contentHeight);
  if (!mapBounds.contains(position)) {
    map.setCenter(position);
  }
  var padding = {
    top: contentHeight + 40.0,
    bottom: offset.y + 40.0,
    left: offset.x * -1.0 + 20.0,
    right: contentWidth + offset.x + 20.0
  };
  return map.panToBounds(new (google.maps.LatLngBounds)(position), padding);
}

var Helpers$1 = {
  widthPadding: 20.0,
  heightPadding: 40.0,
  getPixelPositionOffset: getPixelPositionOffset,
  panBox: panBox
};

function GoogleMap$InfoBox(props) {
  var contentHeight = props.contentHeight;
  var contentWidth = props.contentWidth;
  var position = props.position;
  return JsxRuntime.jsx(Api.OverlayView, {
              position: position,
              mapPaneName: overlayPaneName,
              getPixelPositionOffset: getPixelPositionOffset,
              onLoad: (function (overlayView) {
                  panBox(overlayView, position, contentWidth, contentHeight);
                }),
              children: props.children
            });
}

var InfoBox = {
  Helpers: Helpers$1,
  make: GoogleMap$InfoBox
};

var Options$2 = {};

var KmlLayer = {
  Options: Options$2
};

var make = GoogleMap;

export {
  Coordinates ,
  LatLng ,
  LatLngBounds ,
  ZoomControlOptions ,
  Options ,
  $$Map ,
  Helpers ,
  JsComponent ,
  make ,
  LoadScript ,
  OverlayView ,
  Clusterer ,
  Marker ,
  Cluster ,
  MarkerClusterer ,
  DrawingManager ,
  InfoBox ,
  KmlLayer ,
}
/* default Not a pure module */
